(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

var _trackJsErrors = require('./track-js-errors');

var _trackJsErrors2 = _interopRequireDefault(_trackJsErrors);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _trackJsErrors2.default)();

document.documentElement.className += ' js';

},{"./track-js-errors":2}],2:[function(require,module,exports){
// Track JavaScript errors in Google Analytics
/* global ga */

'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  function link(href) {
    var a = window.document.createElement('a');
    a.href = href;
    return a;
  }

  window.onerror = function (message, file, line, column) {
    var host = link(file).hostname;
    ga('send', {
      'hitType': 'event',
      'eventCategory': (host == window.location.hostname || host == undefined || host == '' ? '' : 'external ') + 'error',
      'eventAction': message,
      'eventLabel': (file + ' LINE: ' + line + (column ? ' COLUMN: ' + column : '')).trim(),
      'nonInteraction': 1
    });
  };
};

},{}]},{},[1]);
